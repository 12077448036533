import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import './pageStyles/privacy-policy.scss'

export default function PrivacyPolicy() {
  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <article id="post-171" className="post-171 page type-page status-publish hentry">
        <div className="entry-content">
          <div className="privacy-pol-l et-l privacy-pol-l--post et-l--post">
            <div className="privacy-pol_builder_inner_content et_builder_inner_content privacy-pol_pb_gutters3 et_pb_gutters3">
              <div className="privacy-pol_pb_section et_pb_section privacy-pol_pb_section_7 et_pb_section_7 privacy-pol_pb_with_background et_pb_with_background privacy-pol_section_regular et_section_regular section_has_divider privacy-pol_pb_bottom_divider et_pb_bottom_divider privacy-pol_pb_top_divider et_pb_top_divider optml-bg-lazyloaded privacy-pol_pb_section_first et_pb_section_first" data-fix-page-container="on" style={{ paddingTop: '168px' }}>
                <div className="privacy-pol_pb_row et_pb_row privacy-pol_pb_row_8 et_pb_row_8 optml-bg-lazyloaded">
                  <div className="privacy-pol_pb_column et_pb_column privacy-pol_pb_column_4_4 et_pb_column_4_4 privacy-pol_pb_column_11 et_pb_column_11  privacy-pol_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough privacy-pol-last-child et-last-child">
                    <div className="privacy-pol_pb_module et_pb_module privacy-pol_pb_text et_pb_text privacy-pol_pb_text_8 et_pb_text_8 privacy-pol_pb_text_align_center et_pb_text_align_center privacy-pol_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                      <div className="privacy-pol_pb_text_inner et_pb_text_inner">Privacy Policy</div>
                    </div>
                  </div>
                </div>
                <div className="privacy-pol_pb_bottom_inside_divider et_pb_bottom_inside_divider" style={{}} />
              </div>
              <div className="privacy-pol_pb_section et_pb_section privacy-pol_pb_section_8 et_pb_section_8 privacy-pol_section_regular et_section_regular">
                <div className="privacy-pol_pb_row et_pb_row privacy-pol_pb_row_9 et_pb_row_9 optml-bg-lazyloaded">
                  <div className="privacy-pol_pb_column et_pb_column privacy-pol_pb_column_4_4 et_pb_column_4_4 privacy-pol_pb_column_12 et_pb_column_12  privacy-pol_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough privacy-pol-last-child et-last-child">
                    <div className="privacy-pol_pb_module et_pb_module privacy-pol_pb_text et_pb_text privacy-pol_pb_text_9 et_pb_text_9 privacy-pol_pb_text_align_left et_pb_text_align_left privacy-pol_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                      <div className="privacy-pol_pb_text_inner et_pb_text_inner">
                        <p>This privacy notice discloses the privacy practices for ARCmedical.com. This privacy notice applies solely to information collected by this web site. It will notify you of the following:</p>
                        <ul>
                          <li>What personally identifiable information is collected from you through the web site, how it is used and with whom it may be shared.</li>
                          <li>What choices are available to you regarding the use of your data.</li>
                          <li>The security procedures in place to protect the misuse of your information.</li>
                          <li>How you can correct any inaccuracies in the information.</li>
                        </ul>
                        <h2>Information Collection, Use, and Sharing</h2>
                        <p>We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.</p>
                        <p>We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request, e.g. to ship an order.</p>
                        <p>Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.</p>
                        <h2>Your Access to and Control Over Information</h2>
                        <p>You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:</p>
                        <ul>
                          <li>See what data we have about you, if any.</li>
                          <li>Change/correct any data we have about you.</li>
                          <li>Have us delete any data we have about you.</li>
                          <li>Express any concern you have about our use of your data.</li>
                        </ul>
                        <h2>Security</h2>
                        <p>We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.</p>
                        <p>If you feel that we are not abiding by this privacy policy, you should contact us immediately via telephone at 312-888-4488.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}